import { 
  submitSurvey, 
  updateQuestionStartStatistics, 
  updateQuestionViewStatistics,
  updateSurveyStartStatistics,
  updateSurveyViewStatistics
} from '../api/survey';
import InboundsApi from '../api/InboundsApi'
import {invertColor} from '../utils/invert-color'
import router from '@/router'
import { isTruthy } from '../configs/operators';
import { EMAIL, PHONE_NUMBER, ZIP_CODE, VOICE_QUESTION, VOICE_RESPONSE, FILE_UPLOAD, MATRIX, CHECKBOX } from '../configs/questionTypes';
import * as amplitude from '@amplitude/analytics-browser';
import Vue from 'vue';
import { db } from '@/firebase'
import firebase from 'firebase/app'
import 'firebase/firestore'
import Handlebars from "handlebars";
import CustomScriptExecutionWorker from 'worker-loader!@/workers/customScriptExecutorWorker.js';
import {saveSurveyToLocalStorage} from '@/utils/formLocalStorage'
import sendMessageToParent from '@/utils/sendMessageToParent'
import i18n from '@/i18n';


function generateStepsFromSurvey(surveyData) {

  const survey = { ...surveyData };
  const steps = []
  if (!survey.settings || !survey.settings.hide_welcome_step) {
    steps.push({ component: 'WelcomeStep', step: 'welcome_screen' });
  }

  // slice() function creates the new instance of an array instead of modifying existing the existing one 
  const questions = survey.questions.slice().sort((a, b) => a.order - b.order)

  for (let i = 0; i < questions.length; i++) {
    if (!questions[i].type) {
      questions[i].type = 'voice-response'
    }

    const step = {
      order: questions[i].order,
      id: questions[i].id,
      questionId: questions[i].id,
      text: questions[i].text,
      audio_url: questions[i].audio_url,
      style: questions[i].style,
      image: questions[i].image_url,
      is_optional: questions[i].is_optional,
      type: questions[i].type == 'plain' ? 'voice-response' : questions[i].type,
      multipleChoiceItems: questions[i].multiple_choice_items,
      media_attachments: questions[i].media_attachments || [],
      enable_text_input: !!questions[i].enable_text_input,
      description: questions[i].description || '',
      video_source: questions[i].video_source || '',
      actions: questions[i].actions && questions[i].actions.length ? questions[i].actions : null,
      component: 'QuestionStep',
      properties: questions[i].properties,
      skippedByLogic: false,
      variableName: questions[i].variable_name || null,
      loopIndex: 0,
    }

    // adding step
    steps.push(step);

  }

  return steps
}

function generateSurveyStyles(survey){

  let styles = {
    background: '#FFF',
    contrastBackground: "#000",
    backgroundTextColor: "#000",
    color: '#000',
    button: {
      background: '#000',
      color: '#fff',
    },
    dark: false,
  }

  try {
    if(survey && survey.style){
      styles = {
        ...survey.style,
        contrastBackground: invertColor(survey.style.background),
        backgroundTextColor: invertColor(survey.style.background, true),
        dark: invertColor(survey.style.background, true) === "#FFFFFF"
      }
    }
  } catch (error) {
    console.log('cannot set styles', error);
  }
  return styles
}

function initVariables(survey){
  const variables = {}
  if (survey && survey.dynamic_variables) {
      const query = router?.currentRoute?.query || {};
      survey.dynamic_variables.forEach(variable => {
          try {
            const defaultValue = query[`dv_${variable.name}`] || query[variable.name] || variable.value;
            if(!defaultValue){
              variables[variable.name] = null;
              return;
            }
            switch(variable.type) {
                case 'boolean':
                    if (defaultValue === 'true' || defaultValue === 'false') {
                        variables[variable.name] = defaultValue === 'true';
                    } else {
                        variables[variable.name] = null;
                    }
                    break;
                case 'number':
                  // eslint-disable-next-line
                    const numberValue = parseFloat(defaultValue);
                    variables[variable.name] = isNaN(numberValue) ? null : numberValue;
                    break;
                case 'text':
                    variables[variable.name] = defaultValue ? String(defaultValue) : null;
                    break;
                case 'object':
                    try {
                        variables[variable.name] = JSON.parse(defaultValue);
                    } catch (error) {
                        variables[variable.name] = null; // set null if parsing fails
                    }
                    break;
                default:
                    variables[variable.name] = null;
                    break;
            }
          } catch (error) {
            variables[variable.name] = null;
          }
      })
  }
  // reserved space for fields variables
  variables['fields'] = {};
  return variables;
}

// TODO use usual date and pass local timezone 
function getCurrentDatetime() {
  let now = new Date();
  return `${now.getFullYear()}-${
      now.getMonth() + 1
  }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
}

function redirect(link, variables){
  // if we are inside of a iframe (or nested iframes), redirect the entire page
  let finalLink = link;
  try {
    const compiledLink = Handlebars.compile(link)
    finalLink = compiledLink? compiledLink(variables) : link;
  } catch (error) {
    console.error('cannot compile link', error);
  }

  if (window.top && window.top !== window) {
    window.top.location.replace(finalLink)
  } else {
    window.location.replace(finalLink)
  }
}

function pauseAllOtherMedias() {
  document.querySelectorAll('audio').forEach(audioElement => {
    audioElement.pause && audioElement.pause();
  });
}

async function runConditionScript(script, variables) {
  try {
      const customScriptWorker = new CustomScriptExecutionWorker();
      customScriptWorker.postMessage({ script, dynamicVariables: variables });

      return new Promise(resolve => {
          const timeoutDuration = 5000;
          const timeout = setTimeout(() => {
              customScriptWorker.terminate();
              console.log("Worker timed out");
              resolve(false); // Resolve with false to indicate timeout
          }, timeoutDuration);

          customScriptWorker.onmessage = function(e) {
              
              if (e.data) {
                  if (e.data.type === "result") {
                    clearTimeout(timeout);
                    resolve(!!e.data.data);
                    customScriptWorker.terminate();
                  }
                  if (e.data.type === "error") {
                    console.error("Worker error:", e.data.data);
                    clearTimeout(timeout);
                    resolve(false); // Resolve with false to indicate error
                    customScriptWorker.terminate();
                  }
              }

              customScriptWorker.onerror = function(error) {
                  clearTimeout(timeout);
                  console.error("Worker encountered an error:", error);
                  resolve(false); // Resolve with false to indicate error
                  customScriptWorker.terminate();
              };
          };
      });
  } catch (error) {
      console.error("Cannot run custom script", error);
      return false;
  }
}

async function processActionConditions(state, action) {
  try {
    
    if(action?.always_trigger){
      return true;
    }
    
    const conditionMap = await Promise.all(action.conditions.map(async condition => {
      const answerObj = state.answers[condition.variable.question_ref];
      const answer = answerObj && answerObj.answer;
      let result = false;
      
      // if condition has a script, run it
      if(condition.script){
        result = condition.script_text? await runConditionScript(condition.script_text, {
          ...state.variables, 
          utility: state.utility, 
          metadata: state.metadata || {}
        }) : false;
      } else {
        result = isTruthy(condition, answer && (!answer.value && answer.value !== 0 ? answer.url : answer.value));
      }
  
      return {
        ...condition,
        result
      };
    }));
  
    // Merge conditions connected with AND statement
    const processedAndOpResults = conditionMap.reduce((reducedArray, currentCondition) => {
      if (reducedArray.length) {
        const previousCondition = reducedArray[reducedArray.length - 1];
        const previousLogOp = previousCondition.log_op || 'and';
        if (previousLogOp === 'and') {
          reducedArray[reducedArray.length - 1].result = previousCondition.result && currentCondition.result;
        } else {
          reducedArray.push(currentCondition);
        }
      } else {
        reducedArray.push(currentCondition);
      }
      return reducedArray;
    }, []);
  
    const finalResult = processedAndOpResults.reduce((previousValue, currentCondition) => {
      return previousValue || currentCondition.result;
    }, false);
  
    return finalResult;
  } catch (error) {
    return false;
  }
}

export default {
    namespaced: true,
    state: {
      steps: [],
      history: [0], // used to track previous steps 
      previousStepNumber: null,
      activeStepNumber: 0,
      activeStep: null,
      survey: null,
      answers: {},
      // deprecated
      information_data: {},
      error: {
        show: false,
        timeout: 2000,
        message: ''
      },
      submissionStatus: '',
      preview: false,
      startedAt: null,
      validatingAnswer: false,
      dialogues: {},
      fsDocId: null,
      metadata: null,
      resumable: false,
      resumed: false,
      variables: {},
      loopLists: {},
      lastFocusedAt: null,
      utility: {
        viewedAt: null,
        startedAt: null,
        respondentId: null,
        resumedAt: null,
        totalOnScreenTime: 0
      },
      singleQuestionWidget: false,
    },
    getters: {
      steps: state => state.steps,
      previousStepNumber: state => state.previousStepNumber,
      activeStepNumber: state => state.activeStepNumber,
      activeStep: state => state.activeStep,
      survey: state => state.survey,
      styles: state => generateSurveyStyles(state.survey),
      answers: state => state.answers,
      questionSteps: state => {
        const questionSteps = state.steps.filter(step => step.component === 'QuestionStep')
        return questionSteps
      },
      error: state => state.error,
      submissionStatus: state => state.submissionStatus,
      localization: state => state.survey && state.survey.settings && state.survey.settings.localization,
      hasLogic: state => state.survey && !!state.survey.questions.find(question => question.actions && question.actions.length>0),
      validatingAnswer: state => state.validatingAnswer,
      dialogues: state => state.dialogues,
      resumed: state => state.resumed,
      variables: state => state.variables,
      loopLists: state => state.loopLists,
      totalOnScreenTime: state => state.utility?.totalOnScreenTime || 0,
      passingVariables: state => ({...state.variables, utility: state.utility, metadata: state.metadata || {}}),
      singleQuestionWidget: state => state.singleQuestionWidget
    },
    actions: {
      async resumeFromLocalStorage({state, dispatch, commit }, surveyData) {
        const { 
          answers,
          history,  
          dialogues,
          variables,
          loopLists,
          steps,
          metadata
        } = surveyData;

        state.resumed = true;
        state.utility.resumedAt = new Date().getTime();

        if(loopLists && typeof loopLists === 'object'  && Object.keys(loopLists).length){
          commit('setLoopLists', loopLists)
          // set steps if looping existed. We are setting steps to avoid generating all the loop steps again
          if(steps && steps.length){
            commit('setSteps', steps)
          }
        }

        commit('setAnswers', answers)
        commit('setHistory', history);
        commit('setDialogues', dialogues);
        commit('setMetadata', metadata);
        commit('setVariables', variables || {});
        commit('setActiveStepNumber', history[history.length - 1]);
        dispatch('showStepByIndex', history[history.length - 1]);

      },
      async showNextSlide({ dispatch, state, commit, getters }) {
        commit('setPreviousStepNumber', state.activeStepNumber);
        dispatch('calculateOnScreenTime');
        pauseAllOtherMedias();
        const answerValue = state.answers[state.activeStep.id]?.answer?.value
        if(state.survey.inbounds){
          // validating phone number 
          if(state.activeStep?.type === PHONE_NUMBER && answerValue){
            try {
              state.validatingAnswer = state.activeStep.id
              const res = await InboundsApi.validatePhoneNumber(answerValue)
              state.validatingAnswer = false
              if(!(res.data && res.data.valid && res.data.fraud_score < 85 && res.data.line_type !== 'Toll Free')){
                return dispatch('showError', i18n.t('form.invalidPhoneNumber', {phone: answerValue}));
              }
            } catch (error) {
              state.validatingAnswer = false
            }
          }
  
          // validating email number 
          if(state.activeStep?.type === EMAIL && answerValue){
            try {
              state.validatingAnswer = state.activeStep.id
              const res = await InboundsApi.validateEmail(answerValue)
              state.validatingAnswer = false
              if(!(res.data && res.data.disposable === false && res.data.fraud_score < 90)){
                return dispatch('showError', i18n.t('form.invalidEmail', {email: answerValue}));
              }
            } catch (error) {
              state.validatingAnswer = false
            }
          }
          
          // validating zip code 
          if(state.activeStep?.type === ZIP_CODE && answerValue){
            try {
              state.validatingAnswer = state.activeStep.id
              const res = await InboundsApi.validateZip(answerValue)
              state.validatingAnswer = false
              if(!(res.data && res.data.city && res.data.state && res.data.county)){
                return dispatch('showError', `"${answerValue}" is invalid US zip code.`);
              }
            } catch (error) {
              state.validatingAnswer = false
            }
          }
        }

        if (state.activeStep && state.activeStep.actions && state.activeStep.actions.length) {
          for (let index = 0; index < state.activeStep.actions.length; index++) {
            const action = state.activeStep.actions[index];

            // we need to skip the action if it's a "skip" action because it's checked before showing the question
            if(action.type === 'skip'){
              continue;
            }

            if(state.singleQuestionWidget){
              continue;
            }

            const triggerAction = await processActionConditions(state, action);

            if(triggerAction){
              if(action.to){
                if(action.type === 'go-to'){
                  const stepIndex = state.steps.findIndex(step=>step.id && step.id === action.to)
                  if(stepIndex>-1 && stepIndex > state.activeStepNumber){
                    return dispatch('showStepByIndex', stepIndex);
                  }
                }
                if(action.type === 'redirect'){
                  if(action.submit_answers){
                    const res = await dispatch('submitAnswers');
                    return res && res.status === 200 && redirect(action.to, getters.passingVariables)
                  }else{
                    return redirect(action.to, getters.passingVariables)
                  }
                }
              }
              if(action.type === 'submit'){
                return dispatch('submitAnswers');
              }
              if(action.type === 'drop-off'){
                return commit('setSubmission', 'complete');
              }
            }
          }
        }

        // LOOP STEP GENERATION
        if(state.activeStep && state.activeStep.component === 'QuestionStep'){

          let questionId = state.activeStep.questionId;

          if(state.loopLists[questionId] && state.activeStep.loopIndex < state.loopLists[questionId].length-1){
            // copy and add a new step here
            const newStep = Object.assign({}, state.activeStep);
            
            newStep.loopIndex = (state.activeStep.loopIndex || 0) + 1;
            // check if element haven't been generated yet
            const newStepId = `${questionId}_loop_${newStep.loopIndex}`;

            const existingLoopStep = state.steps.find(step=>step.id === newStepId); 

            if(!existingLoopStep){

              newStep.id = newStepId;
    
              const steps = [...state.steps];
              // pass the new step into the array of steps after the current step
              steps.splice(state.activeStepNumber + 1, 0, newStep);
              commit('setSteps', steps);
    
              // wait 10 milliseconds to let the new step be added to the DOM
              await new Promise(resolve => setTimeout(resolve, 10));
            }
            
          }
        }


        let nextActiveStepNumber = state.steps.length-1
        if(state.activeStepNumber < state.steps.length-1){
          nextActiveStepNumber = state.activeStepNumber+1;
        }else{
          // if its last question trigger submit
          return dispatch('submitAnswers');
        }

        return dispatch('showStepByIndex', nextActiveStepNumber);
      },

      showPreviousSlide({ state, dispatch, commit }) {
        pauseAllOtherMedias();
        commit('removeLastItemFromHistory')
        // eslint-disable-next-line
        if (!!state.history.length) {
          return dispatch('showStepByIndex', state.history[state.history.length - 1]);
        }
        if (state.activeStepNumber > 0) {
          return dispatch('showStepByIndex', state.activeStepNumber - 1)
        }
        return dispatch('showStepByIndex', 0)
      },
      
      async showStepByIndex({ dispatch, commit, state }, stepIndex) {
        const landingOnTheSameStep = state.activeStepNumber === stepIndex;
        if (stepIndex < state.steps.length && stepIndex >= 0) {
          /* This part gets trigged if we moved to a question by logic go-tos instead of in-order
             we mark all the questions skipped by goto logic with skippedByLogic, which is a way
             to compute percentage progress bar and counter. Vice versa, removing skippedByLogic attr
             when backing up to the previous question that triggered a logical go to */
          if (state.activeStepNumber < stepIndex - 1) {
            for (let i = state.activeStepNumber + 1; i < stepIndex; ++i) {
              state.steps[i] && (state.steps[i].skippedByLogic = true); // questions skipped from the user by go-to logic
            }
          } else if (state.activeStepNumber - 1 > stepIndex) {
            for (let i = stepIndex + 1; i < state.activeStepNumber; ++i) {
              state.steps[i] && (state.steps[i].skippedByLogic = false); // go back to a question that triggered a go-to logic
            }
          }
          /****************************************/

          /**
           * Requirement 1: Disable skip and loop actions for single question widget
           * Requirement 2: Check if the next step has a skip action and if it does, check if the condition is met
           * if it is, skip the step and move to the next one
           * if it's not, show the step
           * if its the last step, submit the form
           */

          if (!state.singleQuestionWidget && state.steps[stepIndex] && state.steps[stepIndex].actions && state.steps[stepIndex].actions.length) {
            for (let index = 0; index < state.steps[stepIndex].actions.length; index++) {
              const action = state.steps[stepIndex].actions[index];
              if(action.type === 'skip'){
                const triggerAction = await processActionConditions(state, action);
                if(triggerAction){
                  if(stepIndex < state.steps.length-1){
                    state.steps[stepIndex].skippedByLogic=true;
                    return dispatch('showStepByIndex', stepIndex+1);
                  }else{
                    // if its last question trigger submit
                    return dispatch('submitAnswers');
                  }
                }
              }

              const generatedLoopsForQuestion = state?.loopLists[state?.steps[stepIndex]?.id]
              // we are making sure that we generate steps only when we are moving forward or if we navigating to the same step
              if(
                state.steps[stepIndex].loopIndex===0 
                && action.type === 'loop' 
                && state.activeStepNumber <= stepIndex 
                && !(landingOnTheSameStep && generatedLoopsForQuestion) // Skip generation if landing on the same step with loops already generated
              ){
                const triggerAction = await processActionConditions(state, action);
                // if the loop previously was generated but now the condition is false, it should be removed including answers and dialogues
                if(!triggerAction && generatedLoopsForQuestion){
                  await dispatch('removeLoopAndRelatedData', state.steps[stepIndex].id);
                }else if(triggerAction){
                  // generate loop items and show the next step
                  await dispatch('generateLoopList', action);

                  // if generated loop list length is 0 or if it doesn't exists then the loop behaves as skip action
                  if(!state.loopLists[state.steps[stepIndex].id] || state.loopLists[state.steps[stepIndex].id]?.length === 0){
                    if(stepIndex < state.steps.length-1){
                      return dispatch('showStepByIndex', stepIndex+1);
                    }else{
                      // if its last question trigger submit
                      return dispatch('submitAnswers');
                    }
                  }
                }
              }
            }
          }

          /****************************************/

          if(state.steps[stepIndex] && state.steps[stepIndex].component === 'QuestionStep' && state.steps[stepIndex].id){
            if(!state.preview && state.steps[stepIndex].loopIndex === 0){
              updateQuestionViewStatistics(state.survey.hash, state.steps[stepIndex].id);
            }
          }
          commit('setActiveStepNumber', stepIndex)
          if(state.history.includes(stepIndex)){
            commit('backupHistoryTo', stepIndex)
          }else{
            commit('addStepToHistory', stepIndex)
          }

          // 100 milliseconds timeout helps to improve the transition between 2 question steps
          setTimeout(()=>commit('setActiveStep', state.steps[stepIndex]), 100)
          
          return ;
        }
      },
      forceStep({commit, state, dispatch}, activeStepForce){
        // making sure to start from initial state
        commit('setSubmission', '')
        dispatch('showStepByIndex', 0)

        if(activeStepForce){
          if(activeStepForce.questionId){
            const stepIndex = state.steps.findIndex(step=>step.id === activeStepForce.questionId)
            return dispatch('showStepByIndex', stepIndex)
          }

          if(activeStepForce.step){
            if(activeStepForce.step === 'goodbye_page'){
              commit('setSubmission', 'complete')
            }
            const stepIndex = state.steps.findIndex(step=>step.step === activeStepForce.step)
            return dispatch('showStepByIndex', stepIndex)
          }
        }
        
      },

      callSurveyViewStat({state}){
        if(!state.preview){
          state.utility.viewedAt = new Date().getTime();
          updateSurveyViewStatistics(state.survey.hash);
          if(window && window.heap){
            window.heap.track('Form View', {
              formId: state.survey.id,
              formVersion: state.survey.version,
              formQuestionsNum: state.survey.questions.length,
              formLanguage: state.survey.language,
            });
          }
        }
      },
      
      initForm({commit, state, dispatch}, survey){
        if(router && router.currentRoute && router.currentRoute.name=== 'single-question-widget'){
          state.singleQuestionWidget = true;
        }
        state.resumable = survey.resumable || false;
        state.utility.respondentId = window.pubUUID || null;
        const steps = generateStepsFromSurvey(survey)
        const variables = initVariables(survey)
        commit('setSurvey', survey)
        commit('setSteps', steps)
        commit('setVariables', variables)
        if(!state.activeStep){
          commit('setActiveStep', steps[0]);
        }
        let metadata = router && router.currentRoute && router.currentRoute.query ? {...router.currentRoute.query} : null
        let accessToken = null;
        if(metadata && Object.keys(metadata).length !== 0){
          // restoring partial response from query params
          if(metadata.v_pr){
            accessToken = metadata.v_pr
            delete metadata.v_pr
          }
          commit('setMetadata', metadata)
        }
        
        if(survey?.fsDoc?.id){
          commit('SET_FS_DOC_ID', survey.fsDoc.id);

          // resume partial response from firestore
          if(accessToken){
            dispatch('resumePartialResponse', survey.fsDoc)
          }else{
            dispatch('showStepByIndex', 0)
            // refresh metadata if its not a restored partial response
            dispatch('updateDocumentWithData', {
              metadata: metadata || null
            })
          }
        }else{
          dispatch('showStepByIndex', 0)
        }
      },

      async updateDocumentWithData({ state }, additionalData) {
        try {

          // we block updating the document if the form is resumable and not resumed to avoid overwriting the data to initial state
          if(state.resumable && !state.resumed){
            return;
          }

          // updating local storage
          saveSurveyToLocalStorage(state, additionalData);

          if (!state.fsDocId) {
              return;
          }
          const docRef = db.collection("partial-responses").doc(state.fsDocId);

          // Transform loopLists if needed
          const transformedLoopLists = {};
          Object.keys(state.loopLists).forEach(key => {
              // If loopLists[key] is an array, convert it to an object
              if (Array.isArray(state.loopLists[key])) {
                  transformedLoopLists[key] = { ...state.loopLists[key] };
              } else {
                  transformedLoopLists[key] = state.loopLists[key];
              }
          });

          // Update the document with the additional data and updated_at timestamp
          await docRef.update({
              ...additionalData,
              formId: state.survey.id,
              steps: state.steps,
              loopLists: transformedLoopLists,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              variables: state.variables || {},
          });

        } catch (error) {
            console.error("Error updating document: ", error);
        }
      },
      updateOrCreateAnswer({commit, state, dispatch}, answerValue){
        const answer = answerValue? {
          // some answers consist of "answer", "valid" fields
          answer: answerValue.answer || answerValue,
          valid: answerValue.valid || true,
          step: state.activeStepNumber,
          fileUploaderStore: answerValue.fileUploaderStore || null,
          loop_item: null
        } : null;

        
        const activeStep = state.steps[state.activeStepNumber]
        if(answer && state.loopLists && activeStep && state.loopLists[activeStep.questionId] && state.loopLists[activeStep.questionId].length){
          answer.loop_item = state.loopLists[activeStep.questionId][activeStep.loopIndex] || null;
        }

        if(activeStep){
          commit('setAnswers', {
            ...state.answers,
            [activeStep.id]: answer
          })
          if(!state.preview && answer && activeStep?.loopIndex === 0){
            updateQuestionStartStatistics(state.survey.hash, activeStep.id);
          }
        }
        

        // we consider the survey started when user adds first answer
        if(!state.startedAt){
          commit('setStartedAt', getCurrentDatetime())
          if(!state.preview){
            state.utility.startedAt = new Date().getTime();
            updateSurveyStartStatistics(state.survey.hash)
            if(window && window.heap){
              window.heap.track('Form Started', {
                formId: state.survey.id,
                formVersion: state.survey.version,
                formQuestionsNum: state.survey.questions.length,
                formLanguage: state.survey.language,
              });
            }
          }
        }

        const documentUpdate = {answers: state.answers}
        let metaChanged = false;
        let variablesChanged = false;
        if(answer && answer.valid === true){
          if(activeStep.type === 'email'){
            commit('setMetadata', {
              email: answer.answer.value
            })
            metaChanged = true;
          }

          if(activeStep.type === 'phone-number'){
            commit('setMetadata', {
              phoneNumber: answer.answer.value
            })
            metaChanged = true;
          }

          if(activeStep.type === 'name'){
            commit('setMetadata', {
              name: answer.answer.value
            })
            metaChanged = true;
          }

          if(activeStep.variableName && ![VOICE_QUESTION, VOICE_RESPONSE, FILE_UPLOAD].includes(activeStep.type)){
            commit('setVariables', {
              ...state.variables,
              fields: {
                ...state.variables.fields,
                [activeStep.variableName]: answer.answer.value
              }
            })
            variablesChanged = true;
          }

        }

        if(metaChanged){
          documentUpdate.metadata = state.metadata || null;
        }

        if(variablesChanged){
          documentUpdate.variables = state.variables || {};
        }

        dispatch('updateDocumentWithData', {
          ...documentUpdate
        })
      },
      showError({commit}, message){

        // we want to make a timer dynamic based on the content size
        // we assume that the person reads 1 word per second (lower the average)
        // eslint-disable-next-line
        const matches = message.match(/[\w\d\’\'-]+/gi);
        let timeout = 2000;
        if(matches){
          timeout = matches.length * 1000
        }

        commit('setError', {
          show: true,
          timeout,
          message,
        })

        setTimeout(()=> {
          commit('setError', {
            show: false,
          })
        }, timeout)
      },
      async submitAnswers({commit, state, dispatch, getters}){
        if (state.preview) {
          return dispatch('showError', 'You can\'t submit voiceform in preview.')
        }
        for (let index = 0; index < state.steps.length; index++) {
          const step = state.steps[index];
          if (step.component === 'QuestionStep' && state.history.includes(index)) {
            if (!step.is_optional && (!state.answers || !state.answers[step.id])) {
              dispatch('showStepByIndex', index);
              const errorMessageRequired = i18n.locale === 'en' ? !!getters.localization && getters.localization.question_error_message || i18n.t('form.questionIsRequired') : i18n.t('form.questionIsRequired');
              return dispatch('showError', errorMessageRequired);
            }
            if (state.answers[step.id] && state.answers[step.id].valid !== true) {
              dispatch('showStepByIndex', index);
              return dispatch('showError', typeof state.answers[step.id].valid === 'string' ? state.answers[step.id].valid : i18n.t('form.answerIsNotValid'));
            }
            // should throw an error if step is required and has probing enabled and the last dialog message is not sufficient
            if(!step.is_optional && step.properties?.enable_probing){
              if(!state.dialogues || !state.dialogues[step.id] || state.dialogues[step.id].length === 0 || !state.dialogues[step.id][state.dialogues[step.id].length - 1].sufficient){
                dispatch('showStepByIndex', index);
                const errorMessageProbing = i18n.locale === 'en' ? !!getters.localization && getters.localization.probing_error_message || i18n.t('form.unfinishedProbingDialogError') : i18n.t('form.unfinishedProbingDialogError');
                return dispatch('showError', errorMessageProbing);
              }
            }

            const query = router.currentRoute.query;
            if (state.answers[step.id] && !(!!query.v_name || !!query.v_email || !!query.v_phone_number) && (step.type === 'name' || step.type === 'email' || step.type === 'phone-number')) {
              localStorage.setItem(step.type, state.answers[step.id].answer.value);
            }
          }
        }

        const reducedAnswers =  Object.entries(state.answers).reduce(function(result, entry) {
          let question_id = entry[0];
          let loop_index = 0;
          if(question_id.includes('_loop_')){
            question_id = question_id.split('_loop_')[0];
            loop_index = parseInt(entry[0].split('_loop_')[1]);
          }

          if (entry[1] && entry[1].answer) {
            const answer = entry[1].answer;

            // check if answer is matrix and convert it into ordered array
            if(answer.type === 'matrix' && answer.value && typeof answer.value === 'object' && typeof entry[1].step === 'number'  && state.steps[entry[1].step] && state.steps[entry[1].step].properties && !state.steps[entry[1].step].properties.row_source_variable){
              let properties = state.steps[entry[1].step].properties;
              let matrixAnswer = answer.value;
              let formattedMatrixAnswer = [];

              if(properties && properties.rows && properties.rows.length && properties.columns && properties.columns.length){
                for (let i = 0; i < properties.rows.length; i++) {
                  const row = properties.rows[i];
                  if(!matrixAnswer[row.id]){
                    continue;
                  }
                  let formattedRow = {
                    row_id: row.id,
                    row_title: row.title,
                    columns: []
                  };

                  let mentionedGroups = [];
                  for (let j = 0; j < properties.columns.length; j++) {
                    const column = properties.columns[j];
                    if(['checkbox',  'radio'].includes(column.type)){
                      let group_name = column.group_name || `default_${column.type}`;
                      if(matrixAnswer[row.id][group_name] && !mentionedGroups.includes(group_name)){
                          const data = {
                            group_name,
                            group_type: column.type,
                            group_value: matrixAnswer[row.id][group_name]
                          }
                          formattedRow.columns.push(data)
                          mentionedGroups.push(group_name)
                      }
                    }else if(matrixAnswer[row.id][column.id]){
                      formattedRow.columns.push(matrixAnswer[row.id][column.id])
                    }
                  }
                  formattedMatrixAnswer.push(formattedRow)
                }
              }

              result.push({
                question_id,
                loop_index,
                loop_item: entry[1].loop_item || null,
                answer: {
                  ...answer,
                  value: formattedMatrixAnswer
                }
              });

            }else{
              result.push({
                question_id,
                loop_index,
                loop_item: entry[1].loop_item || null,
                answer: entry[1].answer,
              });
            }
          }
          return result;
        }, []);

        try {
          commit('setSubmission', 'loading')
          sendMessageToParent({type: 'voiceform.form-submission-loading', surveyHash: state.survey.hash, questionId: state.singleQuestionWidget? state.steps[0].id : null})

          const formEndTime = getCurrentDatetime();

          if(window && window.heap){
            window.heap.track('Form Submission', {
              formId: state.survey.id,
              formVersion: state.survey.version,
              formQuestionsNum: state.survey.questions.length,
              formLanguage: state.survey.language,
              started_at: state.startedAt,
              ended_at: formEndTime
            });
          }

          const payload = {
            answer_data: reducedAnswers,
            started_at: state.startedAt,
            ended_at: formEndTime,
            information_data: state.information_data,
            metadata: state.metadata,
            dialogues: state.dialogues,
            variables: state.variables,
        }

          const res = await submitSurvey(state.survey.id,payload)

          if(res.status === 200){
            amplitude.track('submission', {
              started_at: payload.started_at,
              ended_at: payload.ended_at,
            })
            dispatch('updateDocumentWithData', { submitted: true})
          }

          sendMessageToParent({type: 'voiceform.form-submitted', surveyHash: state.survey.hash, questionId: state.singleQuestionWidget? state.steps[0].id : null})

          commit('setSubmission', 'complete')

          if (window && state.survey && state.survey.redirect_link) {
            setTimeout(() => {
              // if we are inside of a iframe (or nested iframes), redirect the entire page
              if (window.top && window.top !== window) {
                window.top.location.replace(state.survey.redirect_link)
              } else {
                window.location.replace(state.survey.redirect_link)

              }
            }, 2000)
          }

          return res
        } catch (error) {
          commit('setSubmission', '')
          const errorMessage = (error.response && error.response.data && error.response.data.message) || i18n.t('form.failedToSubmitForm')
          sendMessageToParent({type: 'voiceform.form-submission-error', surveyHash: state.survey.hash, questionId: state.singleQuestionWidget? state.steps[0].id : null, errorMessage})
          dispatch(
            'showError',
            errorMessage
          )
        }
      },
      async updateRespondentIp({state, commit}){
        try {
          const res = await InboundsApi.getIp()
          if(res && res.data && res.data.ip){
            commit('setInformationItem', {ip: res.data.ip})
          }
        } catch (error) {
          state.respondentIp = null
        }
      },
      async resumePartialResponse({state, commit, dispatch}, fsDoc){
        try {
            if (!fsDoc) return;
            state.resumed = true;
            state.utility.resumedAt = new Date().getTime();
            if(fsDoc.loopLists && typeof fsDoc.loopLists === 'object'  && Object.keys(fsDoc.loopLists).length){
              commit('setLoopLists', fsDoc.loopLists)
              // set steps if looping existed. We are setting steps to avoid generating all the loop steps again
              if(fsDoc.steps && fsDoc.steps.length){
                commit('setSteps', fsDoc.steps)
              }
            }
            commit('setAnswers', fsDoc.answers);
            commit('setHistory', fsDoc.history);
            commit('setDialogues', fsDoc.dialogues);
            commit('setMetadata', fsDoc.metadata);
            commit('setVariables', fsDoc.variables || {});
            // we are setting this as as last history item so showStepByIndex wouldn't mark previous steps as skipped
            commit('setActiveStepNumber', fsDoc.history[fsDoc.history.length - 1])
            dispatch('showStepByIndex', fsDoc.history[fsDoc.history.length - 1]);
            
        } catch (error) {
            dispatch('showError', i18n.t('form.cannotResumePartialResponse'));
        }
      },
      async restartForm({state, dispatch}){
        try {
          state.resumed = true;
          await dispatch('updateDocumentWithData', {
            answers: {},
            dialogues: {},
            answersCompletion: 0
          })
        } catch (error) {
          console.error("Error restarting form: ", error);
        }
      },
      async removeLoopAndRelatedData({state, dispatch}, question_id){
        let remountStepIndex = -1;
        let remountingStepCopy = null;
        if(state.loopLists && state.loopLists[question_id]){
          state.loopLists[question_id].forEach((loopItem, index) => {
            const stepId = index === 0 ? question_id : `${question_id}_loop_${index}`;
            const loopStepIndex = state.steps.findIndex(step=>step.id === stepId);
            if(index === 0){
              remountStepIndex = loopStepIndex;
              remountingStepCopy = {...state.steps[loopStepIndex]};
            }
            if(loopStepIndex > -1){
              state.steps.splice(loopStepIndex, 1);
            }

            // remove answer
            Vue.delete(state.answers, stepId);
            // remove dialogue
            Vue.delete(state.dialogues, stepId);
          })
        }
        Vue.delete(state.loopLists, question_id);

        dispatch('updateDocumentWithData', {
          answers: state.answers,
          dialogues: state.dialogues,
          loopLists: state.loopLists
        })

        // we need to remount the original step to make sure that local state is reset
        await new Promise(resolve =>{
          setTimeout(() => {
            if(remountStepIndex > -1){
              state.steps.splice(remountStepIndex, 0, remountingStepCopy);
            }
            resolve();
          }, 10);
        });
      },
      async generateLoopList({state, dispatch}, action){
        // eslint-disable-next-line
        return new Promise(async (resolve) => {
          if (action.loop_source) {
            let newLoopList = [];
            if (action.loop_source.type === 'question' && action.loop_source.id && state.answers[action.loop_source.id]) {
              const answer = state.answers[action.loop_source.id]?.answer;
              if (answer.type === MATRIX && answer.value) {
                newLoopList = Object.values(answer.value);
              }
              if(answer.type === CHECKBOX && Array.isArray(answer.value) && answer.value.length > 0){
                newLoopList = answer.value;
              }
            }

      
            if (action.loop_source.type === 'code' && action.loop_source.script) {
              newLoopList = await new Promise((workerResolve) => {
                const script = action.loop_source.script;
                const dynamicVariables = {...state.variables, utility: state.utility, metadata: state.metadata || {}};
                const worker = new CustomScriptExecutionWorker();
                worker.postMessage({script, dynamicVariables});

                const timeoutDuration = 5000;
                const timeout = setTimeout(() => {
                  worker.terminate();
                  console.log('Worker timed out');
                  workerResolve(null); // Resolve with null to indicate timeout
                }, timeoutDuration);

                worker.onmessage = function (e) {
                  if (e.data.type === 'result') {
                    clearTimeout(timeout);
                    if (Array.isArray(e.data.data)) {
                      workerResolve(e.data.data);
                    } else {
                      console.error('Loop source script did not return an array:', e.data.data);
                      workerResolve(null); // Resolve with null to indicate invalid result
                    }
                  }
                };

                worker.onerror = function (error) {
                  clearTimeout(timeout);
                  console.error('Worker encountered an error:', error);
                  workerResolve(null); // Resolve with null to indicate error
                };
              });
            }

            if(newLoopList){
              let remountStepIndex = -1;
              let remountingStepCopy = null;
              // compare new loop with existing one. If it's different reset loop steps, answers and dialogues
              if(state.loopLists && state.loopLists[action.question_id] && JSON.stringify(newLoopList) !== JSON.stringify(state.loopLists[action.question_id])){
                state.loopLists[action.question_id].forEach((loopItem, index) => {
                  const stepId = index === 0 ? action.question_id : `${action.question_id}_loop_${index}`;
                  const loopStepIndex = state.steps.findIndex(step=>step.id === stepId);
                  if(index === 0){
                    remountStepIndex = loopStepIndex;
                    remountingStepCopy = {...state.steps[loopStepIndex]};
                  }
                  if(loopStepIndex > -1){
                    state.steps.splice(loopStepIndex, 1);
                  }

                  // remove answer
                  Vue.delete(state.answers, stepId);
                  // remove dialogue
                  Vue.delete(state.dialogues, stepId);
                })
              }
              Vue.set(state.loopLists, action.question_id, newLoopList);
              dispatch('updateDocumentWithData', {
                answers: state.answers,
                dialogues: state.dialogues,
                loopLists: state.loopLists
              })
              
              // we need to remount the original step to make sure that local state is reset
              setTimeout(() => {
                if(remountStepIndex > -1){
                  state.steps.splice(remountStepIndex, 0, remountingStepCopy);
                }
                resolve();
              }, 0);
            }
          } else {
            resolve(); // Resolve the promise even if action.loop_source is not provided
          }
        });
      },

      calculateOnScreenTime({state, commit}){
        const startTime = state.lastFocusedAt || state.utility.viewedAt;
        if(startTime){
          const diff = new Date().getTime() - startTime;
          commit('setTotalOnScreenTime', state.utility.totalOnScreenTime + diff)
          commit('setFocusedAtTime', new Date().getTime())
        }
      }
      
    },
    mutations: {
      setHistory (state, payload) {
        state.history = payload
      },
      setSteps(state, payload){
        state.steps = payload
      },
      setPreviousStepNumber(state, payload){
        state.previousStepNumber = payload
      },
      setActiveStepNumber(state, payload){
        state.activeStepNumber = payload
      },
      setActiveStep(state, payload){
        state.activeStep = payload
      },
      setSurvey(state, payload){
        state.survey = payload
      },
      setAnswers(state, payload){
        state.answers = payload
      },
      setError(state, payload){
        state.error = {...state.error, ...payload};
      },
      setSubmission(state, payload){
        if(['', 'loading', 'complete'].includes(payload)){
          state.submissionStatus = payload;
        }
      },
      setStartedAt(state, payload){
        state.startedAt = payload;
      },
      setPreview(state, payload){
        state.preview = payload;
      },
      addStepToHistory(state, stepIndex){
        const previousHistoryItem = state.history[state.history.length-1]
        if(previousHistoryItem !== stepIndex){
          return state.history.push(stepIndex)
        }
      },
      removeLastItemFromHistory(state){
        state.previousStepNumber = state.activeStepNumber;
        state.history.pop()
      },

      backupHistoryTo(state, stepIndex){
        const index = state.history.findIndex(h => h === stepIndex )
        state.history = state.history.slice(0, index+1);
      },
      setInformationItem(state, payload){
        state.information_data = {...state.information_data, ...payload}
      },
      setDialogues(state, payload){
          state.dialogues = payload || {}
      },
      addMessage(state, payload){
          state.dialogues = {
              ...state.dialogues,
              [payload.questionId]: [
                  ...(state.dialogues[payload.questionId] || []),
                  payload.message
              ]
          }
      },
      updateLastMessage(state, payload){
          const messages = state.dialogues[payload.questionId]
          if(messages && messages.length){
            Vue.set(messages, messages.length-1, {...payload.message})
          }
      },
      SET_FS_DOC_ID(state, id) {
          state.fsDocId = id;
      },
      SET_RESUMED(state, resumed) {
          state.resumed = resumed;
      },
      setMetadata(state, payload){
        state.metadata = {
          ...state.metadata,
          ...payload
        }
      },
      setVariables(state, variables){
        state.variables = variables
      },
      setLoopLists(state, payload){
        state.loopLists = payload
      },
      setTotalOnScreenTime(state, payload){
        state.utility.totalOnScreenTime = typeof payload === 'number'? payload : 0;
      },
      setFocusedAtTime(state, payload){
        state.lastFocusedAt = payload;
      }
    }
}